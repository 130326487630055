import React from 'react';
import {
    Box,
    Text,
    Heading,
    Center,
    Card,
    Divider,
} from '@chakra-ui/react';
import Footer from '../components/Footer/Footer';

const PrivacyPolicy = () => {
    return (
        <>
        <Box m={10} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
            <Center>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: '1.5rem', md: '2rem' }}
                    fontFamily={'"Quicksand", sans-serif'}
                    letterSpacing={'1.5px'}
                    borderBottom={'3px solid #3A0CA3'}
                    display={'inline'}
                    pb={2}
                >
                    Privacy Policy
                </Heading>
            </Center>
            <Card
                overflow="hidden"
                variant="outline"
                py={{ base: 5, md: 10 }}
                px={{ base: 5, sm: 10 }}
                fontFamily={"'Poppins', sans-serif"}
                borderRadius={'xl'}
                my={5}
            >
                <Text fontSize={{ base: 'sm', md: 'md' }} color="gray.600">
                    <strong>1. Information We Collect</strong><br />
                    We collect information that you provide to us directly, such as your name, email address, and other personal information.
                    <Divider my={4} />
                    <strong>2. How We Use Your Information</strong><br />
                    We use the information we collect for various purposes, including providing and improving our services.
                    <Divider my={4} />
                    <strong>3. Data Security</strong><br />
                    We implement security measures to protect your personal information.
                    <Divider my={4} />
                    <strong>4. Cookies</strong><br />
                    We use cookies to improve your experience on our site.
                    <Divider my={4} />
                </Text>
            </Card>
        </Box>
        <div>
            <Footer/>
        </div>

        </>
    );
};

export default PrivacyPolicy;
