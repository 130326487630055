import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import Home from './pages/Home';
import Navbar from './components/Navbar/Navbar';
import Profile from './pages/Profile';
import ProductDetails from './pages/ProductDetails';
import ProductEnquiry from './pages/ProductEnquiry';
import SellerProductDetailed from './pages/SellerProductDetailed';
import ProductUpload from './pages/ProductUpload';
import ProductsPage from './pages/ProductsPage';
import Blogspage from './pages/Blogspage';
import BlogDetails from './pages/BlogDetails';
import Aboutpage from './pages/Aboutpage';
import Categorywisedata from './pages/Categorywisedata';
import SellerEnquire from './components/SellerProductcard/SellerEnquire';
import SellerEnquiriepage from './pages/SellerEnquiriepage';
import TermsAndConditions from './pages/TermAndConditionpage';
import PrivacyPolicy from './pages/Privacy_policy_page';
import Disclaimer from './pages/Disclaimerpage';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/category" element={<Categorywisedata />} />
        <Route path="/blogs" element={<Blogspage />} />
        <Route path="/ProductEnquiry" element={<ProductEnquiry />} />
        <Route path="/details" element={<ProductDetails />} />
        <Route path="/SellerProductDetail" element={<SellerProductDetailed />} />
        <Route path="/ProductUpload" element={<ProductUpload />} />
        <Route path="/BlogDetails" element={<BlogDetails />} />
        <Route path="/SellerEnquire" element={<SellerEnquiriepage />} />
        <Route path="/Term&condition" element={<TermsAndConditions />} />
        <Route path="/Privacy_Policy" element={<PrivacyPolicy />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
