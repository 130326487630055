import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import axios from "axios";
import { useToast } from '@chakra-ui/react'

export default function SellerLogin() {
    const navigate = useNavigate();
    const toast = useToast()

    const [Primary_Email, setPrimary_Email] = useState();
    const [password, setpassword] = useState();
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://sri-backend.vercel.app/Seller_Login",
                {
                    Primary_Email: Primary_Email,
                    password: password
                }
            );
            toast({
                title: 'Login Successfull.',
                description: response.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            // alert(response.data.message);
            // console.log(response.data)
            localStorage.setItem("SellerID", response.data.data._id);
            localStorage.setItem("SellerToken", response.data.data.token);
            localStorage.setItem("SellerName", response.data.data.Name);
            localStorage.setItem("UserType", response.data.User);
            navigate("/");
            window.scrollTo(0, 0);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            setPrimary_Email("");
            setpassword("");
        } catch (error) {
            toast({
                title: error.response.data.message,
                description: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            console.log(error.response.data.message);
        }
    };



    return (
        <Box>
            <Text fontWeight={600} fontSize={'2rem'} color="black">
                Seller Log In
            </Text>
            <Text color="black">
                New to Speak Up? <Link to={'/signup'} style={{ textDecoration: 'none', color: '#4076ED' }}>Sign Up</Link>
            </Text>
            <form>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1" style={{ color: 'black' }}>
                        Email or Username
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email or username"
                        value={Primary_Email}
                        onChange={(e) => { setPrimary_Email(e.target.value) }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1" style={{ color: 'black' }}>
                        Password
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter your Password"
                        value={password}
                        onChange={(e) => { setpassword(e.target.value) }}
                    />
                </div>
                <Box className="form-group form-check d-flex" justifyContent={'space-between'}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        style={{ accentColor: 'black' }}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1" style={{ color: 'black' }}>
                        Remember Me
                    </label>
                    <Text cursor={'pointer'} color="black">
                        Forget Password
                    </Text>
                </Box>
                <Button onClick={handleSubmit} type="submit" variant={'solid'} bg={'black'} color={'white'} w={'100%'} _hover={{ bg: 'gray.800' }}>
                    Login
                </Button>
            </form>
            {/* <Flex my={3} justifyContent={'center'} gap={5}>
                <FcGoogle size={30} />
                <FaFacebook size={30} color='#1877F2' />
                <FaInstagram size={30} color='#DC3171' />
            </Flex> */}
            <Text textAlign={'center'} color="black">
                Don’t have an Account? <Link to={'/signup'} style={{ textDecoration: 'none', color: '#4076ED' }}>Sign Up</Link>
            </Text>
        </Box>
    );
}
