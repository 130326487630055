import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'

export default function ProductCard({ productdata }) {
  const toast = useToast()


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [Description, setDescription] = useState();
  const [Quantity, setQuantity] = useState();
  const [Price_expected, setPrice_expected] = useState();
  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };
  const product = productdata.product
  const userdata = productdata.user

  const Sellertoken = localStorage.getItem('SellerToken');
  const UserToken = localStorage.getItem('UserToken');



  const CreateEnquire = async () => {
    try {
      const token = localStorage.getItem('UserToken');
      const UserID = localStorage.getItem('UserID');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `https://sri-backend.vercel.app/${UserID}/CreateEnquire`,
        {
          ProductId: product._id,
          Description: Description,
          Quantity: Quantity,
          Price_expected: Price_expected,
        },
        config
      );

      // alert(response.data.message);
      toast({
        title: 'Success.',
        description: response.data.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      setDescription('');
      setPrice_expected('');
      setQuantity('');
    } catch (error) {
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // alert(error.response.data.message);
    }

    closeEditModal();
  };

  const NotLogin =()=>{
    toast({
      title: "Error",
      description: "please Login first",
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
    // alert("please Login first")
    closeEditModal();
  }


  return (
    <Card
      overflow="hidden"
      variant="outline"
      p={5}
      fontFamily={"'Poppins', sans-serif"}
    >
      <div className="container">
        <div className="row">
          <Box
            className="col-sm-12 col-lg-6 d-flex align-items-center my-3"
            justifyContent={'space-between'}
          >
            <div className="row">
              <div className="col-sm-12 col-lg-10 my-2 d-flex align-items-center justify-content-center">
                <Image src={product.Product_Image} alt="" className="img-fluid" maxW={'80%'} />
              </div>
              <div className="col-sm-12 col-lg-2 my-2 d-flex flex-column align-items-center justify-content-center">
                <Flex
                  gap={2}
                  direction={{ base: 'row', md: 'column-reverse' }}
                  minW={'5vw'}
                >
                  <Image src={product.Product_Image} alt="fanSmall" className="img-fluid" />
                  <Image src={product.Product_Image} alt="fanSmall" className="img-fluid" />
                  <Image src={product.Product_Image} alt="fanSmall" className="img-fluid" />
                </Flex>
              </div>
            </div>
          </Box>
          <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center my-3">
            <Stack spacing="4">
              <Box>
                <Heading
                  size="md"
                  textTransform="uppercase"
                  fontFamily={"'Poppins', sans-serif"}
                  lineHeight={'2rem'}
                  letterSpacing={'1px'}
                >
                  {product.Product_Name}
                </Heading>
                <Flex
                  gap={5}
                  align={'center'}
                  fontFamily={"'Poppins', sans-serif"}
                  fontWeight={600}
                  letterSpacing={'1px'}
                >
                  <Text pt="2" fontSize={{ base: "lg", md: "md", lg: "3xl" }} color={'#3a0ca3'}>
                    Rs.{product.Product_Price}/ Piece
                  </Text>
                  <Text pt="2" fontSize={{ base: "md", md: "md", lg: "lg" }}>
                    Get Latest Price
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Text pt="2" fontSize="sm">
                  {product.Product_description}
                </Text>
              </Box>
              <Flex justifyContent={'space-between'}>
                <Text color={'#3a0ca3'}>View Complete Details</Text>
                <Button
                  bg={'#3a0ca3'}
                  color={'white'}
                  _hover={{ bg: '#3a0ca3e1' }}
                  letterSpacing={'1px'}
                  onClick={openEditModal}
                >
                  Enquire
                </Button>
              </Flex>
            </Stack>
          </div>
        </div>
      </div>
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enquire Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <label htmlFor="editName">Description</label>
            <Input
              type="text"
              id="editName"
              placeholder="Description"
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </ModalBody>
          <ModalBody>
            <label htmlFor="editName">Quantity</label>
            <Input
              type="Number"
              id="editName"
              placeholder="Quantity"
              value={Quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </ModalBody>
          <ModalBody>
            <label htmlFor="editName">Price expected</label>
            <Input
              type="Number"
              id="editName"
              placeholder="Price_expected"
              value={Price_expected}
              onChange={(e) => setPrice_expected(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color={'white'}
              bg={'#3a0ca3'}
              size={'md'}
              ml={{ base: '0', sm: 'auto' }}
              my={'auto'}
              letterSpacing={'1px'}
              _hover={{ bg: '#3a0ca3e1' }}
              mr={'10px'}
              onClick={() => {
                if ( !UserToken) {
                  NotLogin();
                } else {
                  CreateEnquire();
                  
                }
              }}
            >
              Save
            </Button>
            <Button onClick={closeEditModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
