import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'
export default function SignUp() {
  const navigate = useNavigate();
  const toast = useToast()

  const [Primary_Email, setPrimary_Email] = useState();
  const [password, setpassword] = useState();
  const [confirm_password, setconfirm_password] = useState();
  const [Name, setName] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://sri-backend.vercel.app/User_Register",
        {
          Name: Name,
          Primary_Email: Primary_Email,
          password: password,
          confirm_password: confirm_password
        }
      );
      toast({
        title: 'SignUp Successfull.',
        description: response.data.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      // alert(response.data.message);
      console.log(response.data)
      navigate("/");
      setPrimary_Email("");
      setName("");
      setpassword("");
      setconfirm_password("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error.response.data.message);
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  };


  return (
    <Box>
      <Text fontWeight={600} fontSize={'2rem'}>Sign Up</Text>
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Full Name</label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter your name"
            value={Name}
            onChange={(e) => { setName(e.target.value) }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email Address</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Email Address"
            value={Primary_Email}
            onChange={(e) => { setPrimary_Email(e.target.value) }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Enter your Password"
            value={password}
            onChange={(e) => { setpassword(e.target.value) }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Confirm Password"
            value={confirm_password}
            onChange={(e) => { setconfirm_password(e.target.value) }}
          />
        </div>
        <Button onClick={handleSubmit} type="submit" variant={'solid'} bg={'black'} color={'white'} w={'100%'} _hover={{ bg: 'gray.800' }}>
          Sign Up
        </Button>
      </form>
      <Text my={3} textAlign={'center'}>Already have an Account?<Link to={'/signup'} style={{ textDecoration: 'none', color: '#4076ED' }}> Log in</Link> </Text>
    </Box>
  );
}
