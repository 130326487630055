import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import { useLocation, useParams } from "react-router";
import SellerProductcard from '../components/SellerProductcard/SellerProductcard';
import SellerProductDetailCard from '../components/SellerProductcard/SellerProductDetailCard';
import Footer from '../components/Footer/Footer';
import { Link } from 'react-router-dom';

function SellerProductDetailed() {
    let location = useLocation();
    const productdata = location.state.item
    return (
        <>
            <Box
                m={10}
                mt={5}
                mb={40}
                mx={3}
                bg={'#edeff6'}
                py={5}
                px={{ base: 2, sm: 5 }}
                borderRadius={'lg'}
            >
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/">
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/details">
                            Detail page
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <SellerProductcard productdata={productdata} />
                <SellerProductDetailCard productdata={productdata} />
            </Box>
            <Footer />
        </>
    )
}

export default SellerProductDetailed


