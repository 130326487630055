import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProfileCard from './Profilecard/ProfileCard';
import Footer from '../Footer/Footer';
import ContactCard from './ContactCard/ContactCard';
import CompanyCard from './CompanyCard/CompanyCard';
import axios from 'axios';
import { TiKey } from 'react-icons/ti';
import Seller_product from '../SellerProduct/Seller_product';
import SellerEnquire from '../SellerProductcard/SellerEnquire';

export default function Details() {

  const [userdata, setUserdata] = useState([]);

  const Get_User_Profile = async () => {
    try {
      const token = localStorage.getItem('UserToken');
      const UserID = localStorage.getItem('UserID');
      const response = await axios.get(`https://sri-backend.vercel.app/${UserID}/GetUserProfile`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setUserdata(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const Get_Seller_Profile = async () => {
    try {
      const token = localStorage.getItem('SellerToken');
      const UserID = localStorage.getItem('SellerID');
      const response = await axios.get(`https://sri-backend.vercel.app/${UserID}/GetSellerProfile`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setUserdata(response.data.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const Sellertoken = localStorage.getItem('SellerToken');

  useEffect(() => {
    if (Sellertoken){
      Get_Seller_Profile();
    }
    else{
      Get_User_Profile();
    }
    
  }, []);

  return (
    <>
      <Box
        m={10}
        mt={5}
        mb={40}
        mx={3}
        bg={'#edeff6'}
        py={5}
        px={{ base: 2, sm: 5 }}
        borderRadius={'lg'}
      >
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/profile">
              Profile page
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <ProfileCard userdata={userdata} />
        <ContactCard userdata={userdata} />
        {Sellertoken && <CompanyCard userdata={userdata} />}
        {Sellertoken && <Seller_product />}
        {/* {Sellertoken && <SellerEnquire />} */}
      </Box>
      <Footer />
    </>
  );
}
