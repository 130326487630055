import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    AspectRatio,
    Spinner
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import { useNavigate } from "react-router";


function Seller_product() {
    const navigate = useNavigate();


    const [products, setProducts] = useState([]);

    const [isloading, setisloading] = useState(false)




    const Get_All_Products = async () => {
        const token = localStorage.getItem('SellerToken');
        const UserID = localStorage.getItem('SellerID');
        try {
            setisloading(true)
            const response = await axios.get(`https://sri-backend.vercel.app/${UserID}/Get_Approved_Product`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(response.data.data);
            setProducts(response.data.data);
            setisloading(false)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Get_All_Products();
    }, []);

    const ProductClick = (item) => {
        navigate('/SellerProductDetail', {
            state: {
                item: item,
            },
        });
        window.scrollTo(0, 0);
    }


    return (
        <div>
            <div>
                <Box m={10} mt={5} mb={40} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'} textAlign={'center'}>
                    <Text
                        fontWeight={600}
                        fontSize={'2rem'}
                        fontFamily={'"Quicksand", sans-serif'}
                        letterSpacing={'1.5px'}
                        borderBottom={'3px solid #3A0CA3'}
                        display={'inline'}
                        pb={2}
                    >
                        Uploaded Seller Products
                    </Text>
                    <Box px={{ base: 2, sm: 5, md: 10 }} mt={10}>
                        {
                            isloading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '200px' }}>
                                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                                </div>
                            ) : (
                                products.length === 0 ? (
                                    <p>No Products are Uploaded Yet</p>
                                ) : (
                                    <Flex flexWrap="wrap" justifyContent="space-between">
                                        {products.map((product, index) => (
                                            <Box key={index} width={{ base: '100%', sm: '48%', md: '30%' }} mb={{ base: 4, md: 8 }}>
                                                <Card maxW="sm">
                                                    <CardBody>
                                                        <AspectRatio ratio={1.3}>
                                                            <Image src={product?.Product_Image} borderRadius="lg" alt="" className="img-fluid" />
                                                        </AspectRatio>
                                                        <Stack mt="6" spacing="3">
                                                            <Heading size="md" textAlign={'left'}>
                                                                {product.Product_Name.slice(0, 50)}...
                                                            </Heading>
                                                            <Text color="#3a0ca3" fontSize="xl" textAlign={'left'} fontWeight={600}>
                                                                Rs {product.Product_Price}/ piece
                                                            </Text>
                                                            <Flex align={'center'} justifyContent={'space-between'} color={'#889099'}>
                                                                <Text>{product.Brand}</Text>
                                                                <Text>{product.Address}</Text>
                                                            </Flex>
                                                            <Button
                                                                size={'md'}
                                                                bg={'#3A0CA3'}
                                                                color={'white'}
                                                                fontFamily={'"Inter", sans-serif'}
                                                                _hover={{ bg: '#3A0CA3e1' }}
                                                                letterSpacing={'1.5px'}
                                                                onClick={() => { ProductClick(product) }}
                                                            >
                                                                Show More
                                                            </Button>
                                                        </Stack>
                                                    </CardBody>
                                                </Card>
                                            </Box>
                                        ))}
                                    </Flex>
                                ))}
                    </Box>
                    <Text color={'#3a0ca3'} my={5} fontSize={'1.5rem'} fontWeight={600} cursor={'pointer'}>
                        {/* See All */}
                    </Text>
                </Box>
            </div>
        </div>

    );
}


export default Seller_product