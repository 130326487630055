import {
  Button,
  Card,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Spinner
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { MdEmail } from 'react-icons/md';
import { HiLocationMarker } from 'react-icons/hi';
import { PhoneIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'

export default function ContactCard({ userdata }) {
  const toast = useToast()

  const [isloading, setisloading] = useState(false)

  useEffect(() => {
    setisloading(true);
    const timer = setTimeout(() => {
      setisloading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [Primary_Number, setPrimary_Number] = useState();
  const [Alternative_Number, setAlternative_Number] = useState();
  const [Primary_Email, setPrimary_Email] = useState('');
  const [Alternative_Email, setAlternative_Email] = useState('');
  const [Address, setAddress] = useState('');

  const openEditModal = () => {
    setIsEditModalOpen(true);
    setPrimary_Number(userdata.Primary_Number)
    setAlternative_Number(userdata.Alternative_Number)
    setPrimary_Email(userdata.Email)
    setAlternative_Email(userdata.Alternative_Email)
    setAddress(userdata.Address)

  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };


  const Sellertoken = localStorage.getItem('SellerToken');
  const UserToken = localStorage.getItem('UserToken');

  const UpdateUserProfile = async () => {
    try {
      const token = localStorage.getItem('UserToken');
      const UserID = localStorage.getItem('UserID');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `https://sri-backend.vercel.app/${UserID}/UpdateUser`,
        {
          Primary_Email: Primary_Email,
          Alternative_Email: Alternative_Email,
          Primary_Number: Primary_Number,
          Alternative_Number: Alternative_Number,
          Address: Address
        },
        config
      );

      window.location.reload();
    } catch (error) {
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // alert(error.response.data.message);

    }

    closeEditModal();
  };

  const UpdateSellerProfile = async () => {
    try {
      const token = localStorage.getItem('SellerToken');
      const UserID = localStorage.getItem('SellerID');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `https://sri-backend.vercel.app/${UserID}/UpdateSellerprofile`,
        {
          Primary_Email: Primary_Email,
          Alternative_Email: Alternative_Email,
          Primary_Number: Primary_Number,
          Alternative_Number: Alternative_Number,
          Address: Address
        },
        config
      );

      window.location.reload();
    } catch (error) {
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // alert(error.response.data.message);
    }

    closeEditModal();
  };

  return (
    <Card
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      variant="outline"
      py={5}
      pl={5}
      pr={{ base: 5, sm: 10 }}
      fontFamily={"'Poppins', sans-serif"}
      borderRadius={'xl'}
      my={5}
    >
      {isloading
        ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '200px' }}>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </div>
        ) : (
          <>
            <Stack minW={'80vw'}>
              <CardHeader>
                <Heading
                  size="lg"
                  fontFamily={"'Poppins', sans-serif"}
                  fontWeight={600}
                >
                  Contact Information
                </Heading>
              </CardHeader>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
                    <Flex gap={3} align={'center'}>
                      <IconButton
                        bg={'#3a0ca3'}
                        size="lg"
                        icon={<PhoneIcon color={'white'} />}
                        _hover={'none'}
                      />
                      <Stack>
                        <Text mb={0} fontWeight={600}>
                          Primary Mobile Number
                        </Text>
                        <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                          {userdata.Primary_Number ? userdata.Primary_Number : 'Default Primary Number'}
                        </Text>
                      </Stack>
                    </Flex>
                  </div>
                  <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
                    <Flex gap={3} align={'center'}>
                      <IconButton
                        bg={'#3a0ca3'}
                        size="lg"
                        icon={<PhoneIcon color={'white'} />}
                        _hover={'none'}
                      />
                      <Stack>
                        <Text mb={0} fontWeight={600}>
                          Alternative Mobile Number
                        </Text>
                        <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                          {userdata.Alternative_Number ? userdata.Alternative_Number : 'Default Alternative Number'}
                        </Text>
                      </Stack>
                    </Flex>
                  </div>
                  <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
                    <Flex gap={3} align={'center'}>
                      <IconButton
                        bg={'#3a0ca3'}
                        size="lg"
                        icon={<MdEmail color={'white'} size={25} />}
                        _hover={'none'}
                      />
                      <Stack>
                        <Text mb={0} fontWeight={600}>
                          Primary Email
                        </Text>
                        <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                          {userdata.Email ? userdata.Email : 'Default Email'}
                        </Text>
                      </Stack>
                    </Flex>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
                    <Flex gap={3} align={'center'}>
                      <IconButton
                        bg={'#3a0ca3'}
                        size="lg"
                        icon={<MdEmail color={'white'} size={25} />}
                        _hover={'none'}
                      />
                      <Stack>
                        <Text mb={0} fontWeight={600}>
                          Alternative Email
                        </Text>
                        <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                          {userdata.Alternative_Email ? userdata.Alternative_Email : 'Default Alternative Email'}
                        </Text>
                      </Stack>
                    </Flex>
                  </div>
                  <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
                    <Flex gap={3} align={'center'}>
                      <IconButton
                        bg={'#3a0ca3'}
                        size="lg"
                        icon={<HiLocationMarker color={'white'} size={25} />}
                        _hover={'none'}
                      />
                      <Stack>
                        <Text mb={0} fontWeight={600}>
                          Address
                        </Text>
                        <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                          {userdata.Address ? userdata.Address : 'Default Address'}
                        </Text>
                      </Stack>
                    </Flex>
                  </div>
                </div>
              </div>
            </Stack>
            <Button
              color={'white'}
              bg={'#3a0ca3'}
              size={'lg'}
              ml={{ base: '0', sm: 'auto' }}
              my={'auto'}
              letterSpacing={'1px'}
              _hover={{ bg: '#3a0ca3e1' }}
              onClick={openEditModal}
            >
              Edit
            </Button>


            <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Contact Information</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <label htmlFor="exampleInputEmail1">Primary Mobile Number</label>
                  <Input
                    type="number"
                    className="form-control"
                    id="exampleInputNumber"
                    placeholder="Primary Mobile Number"
                    value={Primary_Number}
                    onChange={(e) => setPrimary_Number(e.target.value)}
                  />
                </ModalBody>
                <ModalBody>
                  <label htmlFor="exampleInputEmail1">Alternative Mobile Number</label>
                  <Input
                    type="number"
                    className="form-control"
                    id="exampleInputNumber"
                    placeholder="Alternative Mobile Number"
                    value={Alternative_Number}
                    onChange={(e) => setAlternative_Number(e.target.value)}
                  />
                </ModalBody>
                <ModalBody>
                  <label htmlFor="exampleInputEmail1">Primary Email Address</label>
                  <Input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Primary Email Address"
                    value={Primary_Email}
                    onChange={(e) => setPrimary_Email(e.target.value)}
                  />
                </ModalBody>
                <ModalBody>
                  <label htmlFor="exampleInputEmail1">Alternative Email Address</label>
                  <Input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Alternative Email Address"
                    value={Alternative_Email}
                    onChange={(e) => setAlternative_Email(e.target.value)}
                  />
                </ModalBody>
                <ModalBody>
                  <label htmlFor="exampleInputEmail1">Address</label>
                  <Input
                    placeholder="Address"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color={'white'}
                    bg={'#3a0ca3'}
                    size={'md'}
                    ml={{ base: '0', sm: 'auto' }}
                    my={'auto'}
                    letterSpacing={'1px'}
                    _hover={{ bg: '#3a0ca3e1' }}
                    onClick={() => {
                      if (Sellertoken) {
                        UpdateSellerProfile();
                      } else if (UserToken) {
                        UpdateUserProfile();
                      }
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={closeEditModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
    </Card>
  );
}
