import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import axios from "axios";
import { useToast } from '@chakra-ui/react'

export default function Login() {
  const navigate = useNavigate();
  const toast = useToast()

  const [Primary_Email, setPrimary_Email] = useState();
  const [password, setpassword] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://sri-backend.vercel.app/User_Login",
        {
          Primary_Email: Primary_Email,
          password: password
        }
      );
      toast({
        title: 'Login Successfull.',
        description: response.data.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      // console.log(response.data)
      localStorage.setItem("UserID", response.data.data._id);
      localStorage.setItem("UserToken", response.data.data.token);
      localStorage.setItem("UserName", response.data.data.Name);
      localStorage.setItem("UserType", response.data.User);
      navigate("/");
      setPrimary_Email("");
      setpassword("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error.response.data.message)
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  };



  return (
    <Box>
      <Text fontWeight={600} fontSize={'2rem'}>Log In</Text>
      <Text>New to Speak Up? <Link to={'/signup'} style={{ textDecoration: 'none', color: '#4076ED' }}>Sign Up</Link> </Text>
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email or Username</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter your email or username"
            value={Primary_Email}
            onChange={(e) => { setPrimary_Email(e.target.value) }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Enter your Password"
            value={password}
            onChange={(e) => { setpassword(e.target.value) }}
          />
        </div>
        <Box className="form-group form-check d-flex" justifyContent={'space-between'}>
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            style={{ accentColor: 'black' }}
          />
          <label className="form-check-label" htmlFor="exampleCheck1" >
            Remember Me
          </label>
          <Text cursor={'pointer'}>Forget Password</Text>
        </Box>
        <Button onClick={handleSubmit} type="submit" variant={'solid'} bg={'black'} color={'white'} w={'100%'} _hover={{ bg: 'gray.800' }}>
          Login
        </Button>
      </form>
      {/* <Flex my={3} justifyContent={'center'} gap={5}>
        <FcGoogle size={30} />
        <FaFacebook size={30} color='#1877F2' />
        <FaInstagram size={30} color='#DC3171' />
      </Flex> */}
      <Text textAlign={'center'}>Don’t have an Accoount?<Link to={'/signup'} style={{ textDecoration: 'none', color: '#4076ED' }}> Sign Up</Link> </Text>
    </Box>
  );
}
