import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Card,
    CardBody,
    AspectRatio,
    Image,
    Stack,
    Heading,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router";
import Footer from '../components/Footer/Footer';
function BlogDetails() {
    let location = useLocation();
    const blogdata = location.state.item

    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
      }
    return (
        <>
            <Box m={10} mt={5} mb={40} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/">
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/blogs">
                            blogs page
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <div>
                        <Box mt={10} mb={10} textAlign={'center'}>
                            <Box px={10} mt={10}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ width: '100%', padding: '10px' }}>
                                        <Card>
                                            <CardBody>
                                                <AspectRatio ratio={3}>
                                                    <Image src={blogdata?.Blog_Image} borderRadius="lg" alt="" className="img-fluid" style={{ width: '100%', height: '100%' }} />
                                                </AspectRatio>
                                                <Stack mt="6" spacing="3" px={"5"}>
                                                    <Heading size="lg" textAlign={'left'} color={"#3a0ca3"}>
                                                        {blogdata.Blog_Title}...
                                                    </Heading>
                                                    <Text  size="lg" textAlign={'left'} color={"#3a0ca3"}>
                                                    Author - {blogdata.Author_Name}  /  Date - {formatDate(blogdata.date)}
                                                    </Text>
                                                    <Text color="#000" fontSize="lg" textAlign={'left'} fontWeight={600}
                                                        dangerouslySetInnerHTML={{ __html: blogdata.Blog_Content }}>
                                                    </Text>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default BlogDetails