import {
  Link as ChakraLink,
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  Flex,
  Image,
  LinkOverlay,
} from '@chakra-ui/react';
import { FaInstagram, FaLinkedin, FaFacebookF } from 'react-icons/fa';
import { BiEnvelope } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { IconHomeCheck } from '@tabler/icons-react';
import android from '../../assets/android.png'
import ios from '../../assets/ios.png'
import SellerLoginModal from '../SellerLoginModal/SellerLoginModal';
import styles from '../Navbar/Navbar.module.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router";
const SocialButton = ({ children, label, href }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isUploading, setIsUploading] = useState(false);


  const Get_All_Category = async () => {
    try {
      setIsUploading(true)
      const response = await axios.get('https://sri-backend.vercel.app/Get_All_Category', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      setCategories(response.data.data);
      setIsUploading(false)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    Get_All_Category();
  }, []);

  const CategoryClick = (item) => {
    navigate('/category', {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  }

  const [UserToken, setUserToken] = useState();

  const GetUser = async () => {
    const dataToken = localStorage.getItem('UserToken')
    const SellerToken = localStorage.getItem('SellerToken')

    if (dataToken) {
      // console.log(dataToken)
      setUserToken(dataToken)
    }
    else {
      setUserToken(SellerToken)
    }


  }
  useEffect(() => {
    GetUser();
  }, []);
  return (
    <>
      <Box bg="#0b090a" color="white" pos={'relative'}>
        <Container
          bg={'#3a0ca3'}
          color={'white'}
          borderRadius={'xl'}
          align={'center'}
          maxW={'4xl'}
          py={'3rem'}
          pos={'absolute'}
          style={{ left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Text fontSize={'1.5rem'}> Mobile App launching soon</Text>
          <Text fontSize={'1rem'}>And never miss out any update</Text>
          <Text fontSize={'1rem'}>
            We will send you a link, open it on your phone to download the App
          </Text>
          <Flex gap={5} align={'center'} justify={'center'}>
            <Image src={android} alt='android' className='img-fluid' />
            <Image src={ios} alt='ios' className='img-fluid' />
          </Flex>
        </Container>
        <Container as={Stack} maxW={'6xl'} pb={10} pt={60}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
            spacing={8}
          >
            <Stack spacing={6}>
              <a
                className="navbar-brand d-flex align-items-center mr-4"
                href="/"
                style={{ fontWeight: '600' }}
              >
                <Box
                  className="d-inline-block align-top p-2 mx-2"
                  borderRadius={'full'}
                  bg={'#3A0CA3'}
                >
                  <IconHomeCheck color="white" />
                </Box>
                Logo
              </a>
              <Text fontSize={'sm'} mb={0}>
                2728 Hickory StreetSalt Lake City, UT 84104
              </Text>
              <Flex align={'center'} gap={2}>
                <AiOutlinePhone />
                <Text fontSize={'sm'} as={'flex'} align={'center'} mb={0}>
                  +1 206-214-2298
                </Text>
              </Flex>
              <Flex align={'center'} gap={2}>
                <BiEnvelope />
                <Text fontSize={'sm'} as={'flex'} align={'center'} mb={0}>
                  support@rezilla.com
                </Text>
              </Flex>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Quick Links</ListHeader>
              {!UserToken &&
                <a
                  data-toggle="modal"
                  data-target="#exampleModalCenterme"
                >
                  <Box as="a" href={'#'}>
                    Login as Seller
                  </Box>
                </a>
              }
              <Box as={Link} to="/">
                Home
              </Box>
              <Box as={Link} to="/about">
                About
              </Box>
              <Box as={Link} to="/products">
                Products
              </Box>
              <Box as={Link} to="/profile">
                Profile
              </Box>
              <Box as={Link} to="/blogs">
                Blogs
              </Box>
            </Stack>
            <div
              className="modal fade"
              id="exampleModalCenterme"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-backdrop="false"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <SellerLoginModal />
                  </div>
                </div>
              </div>
            </div>
            <Stack align="flex-start">
              <ListHeader> Top Category</ListHeader>
              {categories.slice(0, 4).map((category) => (
                <Box  key={category._id}>
                  <ChakraLink onClick={() => { CategoryClick(category) }}>{category.Category_Name}</ChakraLink>
                </Box>
              ))}
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Subscribe to our Newsletter!</ListHeader>
              <Stack direction={'row'} mb={10}>
                <Input
                  placeholder={'Email address'}
                  bg="white"
                  color={'black'}
                  border={0}
                />
                <IconButton
                  bg="#3a0ca3"
                  color="white"
                  _hover={{
                    bg: '#3a0ca3e1',
                  }}
                  borderRadius={'full'}
                  aria-label="Subscribe"
                  icon={<BsArrowRight size={20} />}
                />
              </Stack>
              <ListHeader>Follow Us on</ListHeader>
              <Stack direction={'row'} spacing={6}>
                <SocialButton label={'Twitter'} href={'#'}>
                  <FaLinkedin size={20} />
                </SocialButton>
                <SocialButton label={'YouTube'} href={'#'}>
                  <FaFacebookF size={20} />
                </SocialButton>
                <SocialButton label={'Instagram'} href={'#'}>
                  <FaInstagram size={20} />
                </SocialButton>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
        <Box bg="#3a0ca3" color="white">
          <Container
            as={Stack}
            maxW={'5xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
            color={'#aaa'}
          >
            <Text mb={0}>© Rezilla – All rights reserved</Text>
            <Stack direction={'row'} spacing={6}>
              <Box  as={Link} to="/Term&condition">
                Terms and Conditions
              </Box>
              <Box as={Link} to="/Privacy_Policy">
                Privacy Policy
              </Box>
              <Box as={Link} to="/Disclaimer">
                Disclaimer
              </Box>
            </Stack>
          </Container>
        </Box>
      </Box>
    </>
  );
}
