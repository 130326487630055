import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Button,
    Card,
    CardBody,
    AspectRatio,
    Image,
    Stack,
    Heading,
    Flex,
    Spinner,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import axios from 'axios';
import { useNavigate } from "react-router";

function Blogspage() {
    const navigate = useNavigate();
    const [All_blogs, setAll_blogs] = useState([]);
    const [isUploading, setIsUploading] = useState(false);


    const Get_All_blogs = async () => {
        try {
            setIsUploading(true)
            const response = await axios.get(`https://sri-backend.vercel.app/Get_All_Blogs`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            setAll_blogs(response.data.data);
            setIsUploading(false)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Get_All_blogs()
    }, []);

    const ProductClick = (item) => {
        navigate('/BlogDetails', {
            state: {
                item: item,
            },
        });
        window.scrollTo(0, 0);
    }


    return (
        <>
            <Box m={10} mt={5} mb={40} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/">
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/blogs">
                            blogs page
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <div>
                        <Box mt={10} mb={10} textAlign={'center'}>
                            <Text
                                fontWeight={600}
                                fontSize={'2rem'}
                                fontFamily={'"Quicksand", sans-serif'}
                                letterSpacing={'1.5px'}
                                borderBottom={'3px solid #3A0CA3'}
                                display={'inline'}
                                pb={2}
                            >
                                All Blogs
                            </Text>
                            <Box px={{ base: 2, sm: 5, md: 10 }} mt={10}>
                                {isUploading ? (
                                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                                ) : (
                                    All_blogs.length === 0 ? (
                                        <text>No Blogs is Uploaded Yet</text>
                                    ) : (
                                        <Flex flexWrap="wrap" justifyContent="space-between">
                                            {All_blogs.map((product, index) => (
                                                <Box key={index} width={{ base: '100%', sm: '48%', md: '30%' }} mb={{ base: 4, md: 8 }}>
                                                    <Card maxW="sm">
                                                        <CardBody>
                                                            <AspectRatio ratio={1.3}>
                                                                <Image src={product?.Blog_Image} borderRadius="lg" alt="" className="img-fluid" />
                                                            </AspectRatio>
                                                            <Stack mt="6" spacing="3">
                                                                <Heading size="md" textAlign={'left'}>
                                                                    {product.Blog_Title}...
                                                                </Heading>
                                                                <Text color="#3a0ca3" fontSize="xl" textAlign={'left'} fontWeight={600}
                                                                    dangerouslySetInnerHTML={{ __html: product.Blog_Content.slice(0, 160) + '...' }}>
                                                                </Text>
                                                                <Flex align={'center'} justifyContent={'space-between'} color={'#889099'}>
                                                                    <Text>{product.Brand}</Text>
                                                                    <Text>{product.Address}</Text>
                                                                </Flex>
                                                                <Button
                                                                    size={'md'}
                                                                    bg={'#3A0CA3'}
                                                                    color={'white'}
                                                                    fontFamily={'"Inter", sans-serif'}
                                                                    _hover={{ bg: '#3A0CA3e1' }}
                                                                    letterSpacing={'1.5px'}
                                                                    onClick={() => { ProductClick(product) }}
                                                                >
                                                                    Read More
                                                                </Button>
                                                            </Stack>
                                                        </CardBody>
                                                    </Card>
                                                </Box>
                                            ))}
                                        </Flex>
                                    ))}
                            </Box>
                            <Text color={'#3a0ca3'} my={5} fontSize={'1.5rem'} fontWeight={600} cursor={'pointer'}>
                                {/* See All */}
                            </Text>
                        </Box>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    );
}

export default Blogspage