import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

export default function ProductDetailCard({ productdata }) {
  const product = productdata.product
  const userdata = productdata.user

  const [isProduct, setIsProduct] = useState('product');
  const productFeatures = product.Product_Features.split('\n');

  function formatDate(inputDate) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  }

  
  const userToken = localStorage.getItem('UserToken');
  const SellerToken = localStorage.getItem('SellerToken');

  const isLoggedIn = userToken || SellerToken;

  
  if (!isLoggedIn) {
    return (
      <Card
        p={5}
        fontFamily={"'Poppins', sans-serif"}
        my={5}
        style={{ position: 'relative' }}
      >
        <Text
          fontSize="lg"
          color="red"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            textAlign: 'center',
          }}
        >
          Please login to view the full Product details.
        </Text>
      </Card>
    );
  }
  


  return (
    <Card
    overflow="hidden"
    variant="outline"
    p={5}
    fontFamily={"'Poppins', sans-serif"}
    my={5}
    // style={blurStyle}
    >
      <Box bg={'#ebedf4'} px={5} pt={2} borderRadius={'lg'}>
        <Flex gap={10}>
          <Button
            variant={'unstyled'}
            borderBottom={isProduct === 'product' ? '4px solid #3a0ca3' : ''}
            borderRadius={'none'}
            onClick={() => setIsProduct('product')}
            fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
          >
            Products Details
          </Button>
          <Button
            variant={'unstyled'}
            borderRadius={'none'}
            borderBottom={isProduct === 'company' ? '4px solid #3a0ca3' : ''}
            onClick={() => setIsProduct('company')}
            fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
          >
            Company Details
          </Button>
        </Flex>
      </Box>
      <Stack spacing="4" my={5}>
        <Box fontFamily={"'Poppins', sans-serif"}>
          <Text
            fontWeight={600}
            letterSpacing={'1px'}
            pt="2"
            fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
            color={'#3a0ca3'}
          >
            Product Specification
          </Text>
        </Box>
        <Stack
          divider={<StackDivider />}
          spacing="2"
          fontFamily={"'Poppins', sans-serif"}
          letterSpacing={'1px'}
        >

          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Power
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {product.Power} W
            </Text>
          </Flex>

          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Color
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {product.Color}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Warranty
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {product.Warrenty} Year
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Brand
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {product.Brand}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Model Name/Number
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {product.Model_name}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Country Of Origin
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {product.Country_of_origin}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      <Text
        fontWeight={600}
        letterSpacing={'1px'}
        pt="2"
        fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
        color={'#3a0ca3'}
      >
        Product Description
      </Text>
      <Text pt="2" fontSize="sm" color={'#767676'} lineHeight={'2rem'}>
        {product.Product_description}
      </Text>
      <Text
        fontWeight={600}
        letterSpacing={'1px'}
        pt="2"
        fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
        color={'#3a0ca3'}
      >
        Features
      </Text>
      {productFeatures.map((feature, index) => (
        <Text pt="2" fontSize="sm" fontWeight={600} letterSpacing={'1px'} key={index}>
          <li >{feature}</li>
        </Text>

      ))}
      <Text
        fontWeight={600}
        letterSpacing={'1px'}
        pt="2"
        fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
        color={'#3a0ca3'}
      >
        Available Color:
      </Text>
      <Text pt="2" fontSize="sm" fontWeight={600} letterSpacing={'1px'}>
        {product?.Available_color?.join(', ')}
      </Text>
      <Stack spacing="4" my={5}>
        <Box fontFamily={"'Poppins', sans-serif"}>
          <Text
            fontWeight={600}
            letterSpacing={'1px'}
            pt="2"
            fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
            color={'#3a0ca3'}
          >
            About the Company
          </Text>
        </Box>
        <Stack
          divider={<StackDivider />}
          spacing="2"
          fontFamily={"'Poppins', sans-serif"}
          letterSpacing={'1px'}
        >
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Company Name
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Company_Name}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Primary Number
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Primary_Number}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Alternative Number
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Alternative_Number}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Primary Email
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Primary_Email}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Alternative Email
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Alternative_Email}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
            Company Website
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Company_Website}
            </Text>
          </Flex>
          
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Legal Status For Firm
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              Limited Company (Ltd./Pvt.Ltd.)
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Nature Of Business
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              Manufacturer
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Member Since
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {formatDate(userdata.date)}
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              GST Number
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              06aa34aa78gh596354
            </Text>
          </Flex>
          <Flex gap={30} justifyContent={'space-between'}>
            <Text fontSize="sm" color={'#767676'} mb={0}>
              Address
            </Text>
            <Text fontSize="sm" fontWeight={600} mb={0}>
              {userdata.Address}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      {/* <Text pt="2" fontSize="sm" color={'#767676'} lineHeight={'2rem'}>
        The Siddharth Shriram group was founded by the legendary Lala Shriram in 1889. The group’s businesses extended to textiles, chemicals, sugar, automobiles, engines, nylon tyre cord, automotive components, edible oil, heavy chemicals (fertilizers), engineering foundries, sewing machines, fans and home appliances. In 1989, the group underwent a major transformation, following the segregation of business interests across the new generation - the descendants of Lala Shriram. The group’s proud heritage and achievement over the years have earned it the trust and respect of its consumers both in India and abroad.
      </Text>
      <Text pt="2" fontSize="sm" color={'#767676'} lineHeight={'2rem'} mt={5}>
        Usha International Limited (the Company), a constituent of the Siddharth Shriram group, was formed in 1935 and started doing business under the brand name, Usha. Over the decades, this brand name has become a household name in India while the company has diversified into the business of new age home appliances, sewing machines, fans, power products, water cooler, water dispensers, modern farm equipments and auto components.
      </Text> */}
    </Card>
  );
}
