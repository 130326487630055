import React, { useState, useEffect } from 'react'

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Card,
    Spinner,
    Text,
    Center
} from '@chakra-ui/react'
import axios from 'axios';
function SellerEnquire() {

    const [All_Enquiry, setAll_Enquiry] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const Get_All_Blogs = async () => {
        try {
            const token = localStorage.getItem('SellerToken');
            const UserID = localStorage.getItem('SellerID');
            setIsUploading(true)
            const response = await axios.get(`https://sri-backend.vercel.app/${UserID}/GetEnquireforseller`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(response.data.data, 'aaa');
            setAll_Enquiry(response.data.data);
            setIsUploading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Get_All_Blogs();
    }, []);


    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <>
            <Center>
                <Text
                    fontWeight={600}
                    fontSize={{ base: '1.5rem', md: '2rem' }}
                    fontFamily={'"Quicksand", sans-serif'}
                    letterSpacing={'1.5px'}
                    borderBottom={'3px solid #3A0CA3'}
                    display={'inline'}
                    pb={2}
                >
                    Seller Products Enquire
                </Text>
            </Center>
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow="hidden"
                variant="outline"
                py={{ base: 5, md: 10 }}
                px={{ base: 5, sm: 10 }}
                fontFamily={"'Poppins', sans-serif"}
                borderRadius={'xl'}
                my={5}
            >
                <TableContainer>
                    <Table variant='striped' colorScheme='teal' fontSize={{ base: 'xs', md: 'sm' }}>
                        <Thead>
                            <Tr>
                                <Th>S. No.</Th>
                                <Th>Product_Name</Th>
                                <Th>UserName</Th>
                                <Th>User Number</Th>
                                <Th isNumeric>Quantity</Th>
                                <Th isNumeric>Expected Price</Th>
                                <Th isNumeric>Date Created</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {isUploading ? (
                                <Tr>
                                    <Td colSpan={6} style={{ textAlign: 'center' }}>
                                        <Spinner />
                                    </Td>
                                </Tr>
                            ) : All_Enquiry.length <= 0 ? (
                                <Td variant="h6" align="center">
                                    No Enquiry in the list.
                                </Td>
                            ) : (
                                All_Enquiry.map((data, index) => (
                                    <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td>{data.Product.Product_Name.slice(0, 40)}</Td>
                                        <Td>{data.User.UserName}</Td>
                                        <Td>{data.User.Primary_Number}</Td>
                                        <Td>{data.Quantity}</Td>
                                        <Td>RS. {data.Price_expected}</Td>
                                        <Td>{formatDate(data.date)}</Td>
                                    </Tr>
                                )))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    )
}

export default SellerEnquire