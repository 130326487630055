import {
    Box,
    Button,
    Card,
    Flex,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    StackDivider,
    Text,
  } from '@chakra-ui/react';
  import React from 'react';
  import fan from '../../assets/fan.png';
  import fanSmall from '../../assets/fanSmall.png';
  
  function SellerProductcard({productdata}) {
    const product =productdata
    return (
      <Card
        overflow="hidden"
        variant="outline"
        p={5}
        fontFamily={"'Poppins', sans-serif"}
      >
        <div className="container">
          <div className="row">
            <Box
              className="col-sm-12 col-lg-6 d-flex align-items-center my-3"
              justifyContent={'space-between'}
            >
              <div className="row">
                <div className="col-sm-12 col-lg-10 my-2 d-flex align-items-center justify-content-center">
                  <Image src={product.Product_Image} alt="fan" className="img-fluid" maxW={'80%'} />
                </div>
                <div className="col-sm-12 col-lg-2 my-2 d-flex flex-column align-items-center justify-content-center">
                  <Flex
                    gap={2}
                    direction={{ base: 'row', md: 'column-reverse' }}
                    minW={'5vw'}
                  >
                    <Image src={product.Product_Image} alt="fanSmall" className="img-fluid" />
                    <Image src={product.Product_Image} alt="fanSmall" className="img-fluid" />
                    <Image src={product.Product_Image} alt="fanSmall" className="img-fluid" />
                  </Flex>
                </div>
              </div>
            </Box>
            <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center my-3">
              <Stack spacing="4">
                <Box>
                  <Heading
                    size="md"
                    textTransform="uppercase"
                    fontFamily={"'Poppins', sans-serif"}
                    lineHeight={'2rem'}
                    letterSpacing={'1px'}
                  >
                    {product.Product_Name}
                  </Heading>
                  <Flex
                    gap={5}
                    align={'center'}
                    fontFamily={"'Poppins', sans-serif"}
                    fontWeight={600}
                    letterSpacing={'1px'}
                  >
                    <Text pt="2" fontSize={{ base: "lg", md: "md", lg: "3xl" }} color={'#3a0ca3'}>
                      Rs.{product.Product_Price}/ Piece
                    </Text>
                    <Text pt="2" fontSize={{ base: "md", md: "md", lg: "lg" }}>
                      Get Latest Price
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  <Text pt="2" fontSize="sm">
                   {product.Product_description}
                  </Text>
                </Box>
                <Flex justifyContent={'space-between'}>
                  <Text color={'#3a0ca3'}>View Complete Details</Text>
                  <Button
                    bg={'#3a0ca3'}
                    color={'white'}
                    _hover={{ bg: '#3a0ca3e1' }}
                    letterSpacing={'1px'}
                  >
                    Update Product
                  </Button>
                </Flex>
              </Stack>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  
export default SellerProductcard
  