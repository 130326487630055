import React, { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { Box, Button, Flex, Input, Select, Text, BreadcrumbLink, Breadcrumb } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import axios from 'axios';
import { useNavigate } from "react-router";

export default function Header() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const [Selectedcity, setSelectedcity] = useState('');
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [City, setCity] = useState([]);



  useEffect(() => {
    const fetchSuggestedProducts = async () => {
      try {
        if (searchTerm) {
          const response = await axios.get(`https://sri-backend.vercel.app/Get_All_Approved_product_for_User?search=${searchTerm}&city=${Selectedcity}`, {
          });
          setSuggestedProducts(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching suggested products:', error);
      }
    };


    fetchSuggestedProducts();
    Get_allCity();
  }, [searchTerm]);



  const Get_allCity = async () => {
    try {
      const response = await axios.get(`https://sri-backend.vercel.app/Get_All_Popular_City`, {
      });
      console.log(response.data.data, 'aaa');
      setCity(response.data.data);
    } catch (error) {
      console.error('Error fetching suggested products:', error);
    }
  }

  const ProductClick = (item) => {
    navigate('/details', {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  }



  return (
    <div className={styles.headerContainer}>
      <div className={`${styles.textContainer}`}>
        <Text color={'white'} minW={'70vw'} letterSpacing={'1.5px'}>
          Search for products & find <br /> verified sellers near you
        </Text>
        <Text color={'white'} fontSize={'1rem'} letterSpacing={'1px'} >
          Buying a home is a life-changing experience, so <br /> shouldn’t your
          real estate agent be a life changer
        </Text>
        <Button
          size={'lg'}
          bg={'#3A0CA3'}
          color={'white'}
          fontFamily={'"Inter", sans-serif'}
          _hover={{ bg: '#3A0CA3e1' }}
          letterSpacing={'1.5px'}
        >
          EXPLORE NOW
        </Button>
      </div>
      <Box
        className={`${styles.searchBarContainer}`}
        padding={'0 3rem'}
        zIndex={1}
        pos={'absolute'}
        bg={'white'}
        borderRadius={'lg'}
        minW={'70vw'}
        maxW={'100vw'}
        boxShadow={'0 0 10px black'}
      >
        <Flex alignItems={'center'} gap={3}>
          <Select
            variant="unstyled"
            placeholder="All India"
            w={'10rem'}
            color={'#889099'}
            value={Selectedcity}
            onChange={(e) => setSelectedcity(e.target.value)}
          >
            {City.map((city) => (
              <option key={city._id} value={city._id}>
                {city.City_Name}
              </option>
            ))}
          </Select>
          <FiSearch size={40} />
          <Input
            variant={'unstyled'}
            type="text"
            focusBorderColor="none"
            _hover={{ border: 'none' }}
            minH={'5rem'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            size={'lg'}
            bg={'#3A0CA3'}
            color={'white'}
            fontFamily={'"Inter", sans-serif'}
            _hover={{ bg: '#3A0CA3e1' }}
            letterSpacing={'1.5px'}
          >
            Search
          </Button>
        </Flex>
        {suggestedProducts.length > 0 && (
          <div>
            <ul>
              {suggestedProducts.map((product) => (
                <Breadcrumb>
                  <BreadcrumbLink key={product.product._id} onClick={() => { ProductClick(product) }} >
                    {product.product.Product_Name.slice(0, 20)}...
                  </BreadcrumbLink>
                </Breadcrumb>
              ))}
            </ul>
          </div>
        )}
      </Box>
    </div>
  );
}
