import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import SellerEnquire from '../components/SellerProductcard/SellerEnquire';

function SellerEnquiriepage() {

  return (
    <>
      <Box
        m={10}
        mt={5}
        mb={40}
        mx={3}
        bg={'#edeff6'}
        py={5}
        px={{ base: 2, sm: 5 }}
        borderRadius={'lg'}
      >
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/profile">
              Seller Enquire
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
         <SellerEnquire />
      </Box>
      <Footer />
    </>
  );
}

export default SellerEnquiriepage
