import {
  Button,
  chakra,
  Card,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Spinner
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { PhoneIcon } from '@chakra-ui/icons';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg='#3a0ca3'
      rounded={'full'}
      w={6}
      h={6}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function CompanyCard({ userdata }) {
  const toast = useToast()

  const [isloading, setisloading] = useState(false)

  useEffect(() => {
    setisloading(true);
    const timer = setTimeout(() => {
      setisloading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [Company_Name, setCompany_Name] = useState();
  const [Company_Website, setCompany_Website] = useState();
  const [Gstin, setGstin] = useState();
  const [Pan_Number, setPan_Number] = useState();

  const openEditModal = () => {
    setIsEditModalOpen(true);
    setCompany_Name(userdata.Company_Name)
    setCompany_Website(userdata.Company_Website)
    setGstin(userdata.Gstin)
    setPan_Number(userdata.Pan_Number)


  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };


  const UpdateSellerProfile = async () => {
    try {
      const token = localStorage.getItem('SellerToken');
      const UserID = localStorage.getItem('SellerID');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `https://sri-backend.vercel.app/${UserID}/UpdateSellerprofile`,
        {
          Company_Name: Company_Name,
          Company_Website: Company_Website,
          Gstin: Gstin,
          Pan_Number: Pan_Number
        },
        config
      );

      window.location.reload();
    } catch (error) {
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // alert(error.response.data.message);
    }

    closeEditModal();
  };

  return (
    <Card
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      variant="outline"
      py={5}
      pl={5}
      pr={{ base: 5, sm: 10 }}
      fontFamily={"'Poppins', sans-serif"}
      borderRadius={'xl'}
      my={5}
    >
      {
        isloading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '200px' }}>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </div>
      ) : (
      <>
      <Stack minW={'80vw'}>
        <CardHeader>
          <Heading
            size="lg"
            fontFamily={"'Poppins', sans-serif"}
            fontWeight={600}
          >
            Company Information
          </Heading>
        </CardHeader>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
              <Flex gap={3} align={'center'}>
                <IconButton
                  bg={'#3a0ca3'}
                  size="lg"
                  icon={<PhoneIcon color={'white'} />}
                  _hover={'none'}
                />
                <Stack>
                  <Text mb={0} fontWeight={600}>
                    Company Name
                  </Text>
                  <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                    {userdata.Company_Name ? userdata.Company_Name : 'Default Company_Name'}
                  </Text>
                </Stack>
              </Flex>
            </div>
            <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
              <Flex gap={3} align={'center'}>
                <IconButton
                  bg={'#3a0ca3'}
                  size="lg"
                  icon={<PhoneIcon color={'white'} />}
                  _hover={'none'}
                />
                <Stack>
                  <Text mb={0} fontWeight={600}>
                    Company Website
                  </Text>
                  <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                    {userdata.Company_Website ? userdata.Company_Website : 'Default Company Website'}
                  </Text>
                </Stack>
              </Flex>
            </div>
            <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
              <Flex gap={3} align={'center'}>
                <IconButton
                  bg={'#3a0ca3'}
                  size="lg"
                  icon={<PhoneIcon color={'white'} />}
                  _hover={'none'}
                />
                <Stack>
                  <Text mb={0} fontWeight={600}>
                    GSTIN
                  </Text>
                  <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                    {userdata.Gstin ? userdata.Gstin : 'Default Gstin'}
                  </Text>
                </Stack>
              </Flex>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4 d-flex justify-content-start align-items-center my-3">
              <Flex gap={3} align={'center'}>
                <IconButton
                  bg={'#3a0ca3'}
                  size="lg"
                  icon={<PhoneIcon color={'white'} />}
                  _hover={'none'}
                />
                <Stack>
                  <Text mb={0} fontWeight={600}>
                    Pan
                  </Text>
                  <Text mb={0} fontWeight={600} color={'#3a0ca3'}>
                    {userdata.Pan_Number ? userdata.Pan_Number : 'Default Pan Number'}
                  </Text>
                </Stack>
              </Flex>
            </div>
          </div>
        </div>
      </Stack>
      <Button
        color={'white'}
        bg={'#3a0ca3'}
        size={'lg'}
        ml={{ base: '0', sm: 'auto' }}
        my={'auto'}
        letterSpacing={'1px'}
        _hover={{ bg: '#3a0ca3e1' }}
        onClick={openEditModal}
      >
        Edit
      </Button>


      <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Company Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <label htmlFor="exampleInputEmail1">Company Name</label>
            <Input
              type="exampleInputEmail1"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Company Name"
              value={Company_Name}
              onChange={(e) => setCompany_Name(e.target.value)}
            />
          </ModalBody>
          <ModalBody>
            <label htmlFor="exampleInputEmail1">Company Website</label>
            <Input
              type="exampleInputEmail1"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Company Website"
              value={Company_Website}
              onChange={(e) => setCompany_Website(e.target.value)}
            />
          </ModalBody>
          <ModalBody>
            <label htmlFor="exampleInputEmail1">Gstin</label>
            <Input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Gstin"
              value={Gstin}
              onChange={(e) => setGstin(e.target.value)}
            />
          </ModalBody>
          <ModalBody>
            <label htmlFor="exampleInputEmail1">Pan Number</label>
            <Input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Pan Number"
              value={Pan_Number}
              onChange={(e) => setPan_Number(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color={'white'}
              bg={'#3a0ca3'}
              size={'md'}
              ml={{ base: '0', sm: 'auto' }}
              my={'auto'}
              letterSpacing={'1px'}
              _hover={{ bg: '#3a0ca3e1' }}
              onClick={UpdateSellerProfile}
            >
              Save
            </Button>
            <Button
              onClick={closeEditModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
      )
    }
    </Card>
  );
}
