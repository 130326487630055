import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Button,
    Card,
    CardBody,
    AspectRatio,
    Image,
    Stack,
    Heading,
    Flex,
    Spinner,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import axios from 'axios';
import { useNavigate } from "react-router";

function Aboutpage() {


    return (
        <>
            <Box m={10} mt={5} mb={40} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/">
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/about">
                            About US
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <div>
                        <Box mt={10} mb={10} textAlign={'center'}>
                            <Box  mt={10}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ width: '100%', padding: '10px' }}>
                                        <Card>
                                            <CardBody>
                                                <AspectRatio ratio={3}>
                                                    <Image src="https://www.searchenginejournal.com/wp-content/uploads/2020/12/ecommerce-mcommerce-featured-image-5fd09a3a5ff2a.png" borderRadius="lg" alt="" className="img-fluid" style={{ width: '100%', height: '100%' }} />
                                                </AspectRatio>
                                                <Stack mt="6" spacing="3" px={"5"}>
                                                    <Heading size="lg" textAlign={'left'} color={"#3a0ca3"}>
                                                        About Us
                                                    </Heading>
                                                    <Text size="lg" textAlign={'left'} color={"#3a0ca3"}>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id magna felis. Vivamus ornare maximus elit, nec scelerisque lorem feugiat sit amet. Nullam tristique, lorem vitae tincidunt aliquet, nisl arcu ornare orci, at tincidunt purus nulla vel nisi. Nam dictum, dui ac sollicitudin tempus, diam magna rhoncus dolor, sit amet mattis elit lacus quis sem. Aenean vel neque mauris. Mauris ornare congue arcu nec tristique. In in mi vel elit dapibus pellentesque in eu tortor. Suspendisse sit amet metus nec risus dapibus placerat et ut ligula.
                                                    </Text>
                                                    <Text color="#000" fontSize="lg" textAlign={'left'} fontWeight={600}>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id magna felis. Vivamus ornare maximus elit, nec scelerisque lorem feugiat sit amet. Nullam tristique, lorem vitae tincidunt aliquet, nisl arcu ornare orci, at tincidunt purus nulla vel nisi. Nam dictum, dui ac sollicitudin tempus, diam magna rhoncus dolor, sit amet mattis elit lacus quis sem. Aenean vel neque mauris. Mauris ornare congue arcu nec tristique. In in mi vel elit dapibus pellentesque in eu tortor. Suspendisse sit amet metus nec risus dapibus placerat et ut ligula.
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id magna felis. Vivamus ornare maximus elit, nec scelerisque lorem feugiat sit amet. Nullam tristique, lorem vitae tincidunt aliquet, nisl arcu ornare orci, at tincidunt purus nulla vel nisi. Nam dictum, dui ac sollicitudin tempus, diam magna rhoncus dolor, sit amet mattis elit lacus quis sem. Aenean vel neque mauris. Mauris ornare congue arcu nec tristique. In in mi vel elit dapibus pellentesque in eu tortor. Suspendisse sit amet metus nec risus dapibus placerat et ut ligula.
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id magna felis. Vivamus ornare maximus elit, nec scelerisque lorem feugiat sit amet. Nullam tristique, lorem vitae tincidunt aliquet, nisl arcu ornare orci, at tincidunt purus nulla vel nisi. Nam dictum, dui ac sollicitudin tempus, diam magna rhoncus dolor, sit amet mattis elit lacus quis sem. Aenean vel neque mauris. Mauris ornare congue arcu nec tristique. In in mi vel elit dapibus pellentesque in eu tortor. Suspendisse sit amet metus nec risus dapibus placerat et ut ligula.
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id magna felis. Vivamus ornare maximus elit, nec scelerisque lorem feugiat sit amet. Nullam tristique, lorem vitae tincidunt aliquet, nisl arcu ornare orci, at tincidunt purus nulla vel nisi. Nam dictum, dui ac sollicitudin tempus, diam magna rhoncus dolor, sit amet mattis elit lacus quis sem. Aenean vel neque mauris. Mauris ornare congue arcu nec tristique. In in mi vel elit dapibus pellentesque in eu tortor. Suspendisse sit amet metus nec risus dapibus placerat et ut ligula.
                                                    </Text>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default Aboutpage