import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  AspectRatio,
  Spinner
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import { useNavigate } from "react-router";


export default function Trending() {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [isUploading, setIsUploading] = useState(false);


  const Get_All_Tranding_products = async () => {
    try {
      setIsUploading(true)
      const response = await axios.get('https://sri-backend.vercel.app/Get_Approved_Product_for_all_user', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      // console.log(response.data.data);
      setProducts(response.data.data);
      setIsUploading(false)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    Get_All_Tranding_products();
  }, []);

  const ProductClick = (item) => {
    navigate('/details', {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  }


  return (
    <div>
      <div>
        <Box mt={20} mb={10} textAlign={'center'}>
          <Text
            fontWeight={600}
            fontSize={'2rem'}
            fontFamily={'"Quicksand", sans-serif'}
            letterSpacing={'1.5px'}
            borderBottom={'3px solid #3A0CA3'}
            display={'inline'}
            pb={2}
          >
            Trending Products
          </Text>
          <Box px={10} mt={10}>
            <Swiper
              modules={[Navigation]}
              loop={true}
              spaceBetween={50}
              slidesPerView={3}
              navigation={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            >
              {isUploading ? (
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              ) : (
                products?.map((product, index) => (
                  <SwiperSlide key={index}>
                    <Card maxW="sm">
                      <CardBody>
                        <AspectRatio ratio={1.3}>
                          <Image src={product?.product?.Product_Image} borderRadius="lg" alt="" className="img-fluid" />
                        </AspectRatio>
                        <Stack mt="6" spacing="3">
                          <Heading size="md" textAlign={'left'}>
                            {product?.product?.Product_Name.slice(0, 80)}...
                          </Heading>
                          <Text color="#3a0ca3" fontSize="xl" textAlign={'left'} fontWeight={600}>
                            Rs {product?.product?.Product_Price}/ piece
                          </Text>
                          <Flex align={'center'} justifyContent={'space-between'} color={'#889099'}>
                            <Text>{product?.product?.Brand}</Text>
                            <Text>{product?.user?.Address}</Text>
                          </Flex>
                          <Button
                            size={'md'}
                            bg={'#3A0CA3'}
                            color={'white'}
                            fontFamily={'"Inter", sans-serif'}
                            _hover={{ bg: '#3A0CA3e1' }}
                            letterSpacing={'1.5px'}
                            onClick={() => { ProductClick(product) }}
                          >
                            Get Price
                          </Button>
                        </Stack>
                      </CardBody>
                    </Card>
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </Box>
          <Text color={'#3a0ca3'} my={5} fontSize={'1.5rem'} fontWeight={600} cursor={'pointer'}>
            {/* See All */}
          </Text>
        </Box>
      </div>
    </div>
  );
}
