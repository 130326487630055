import React from 'react'
import Details from '../components/Details/Details'

export default function Profile() {
  return (
    <>
      <Details/>
    </>
  )
}
