import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Select,
    VStack,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Card
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'

function ProductEnquiry() {
    const toast = useToast()

    const navigate = useNavigate();


    const [options, setOptions] = useState([]);



    const Get_All_Category = async () => {
        try {
            const response = await axios.get('https://sri-backend.vercel.app/Get_All_Category', {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            console.log(response.data.data);
            setOptions(response.data.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Get_All_Category();
    }, []);



    const [Product_Name, setProduct_Name] = useState();
    const [Product_Category, setProduct_Category] = useState();
    const [Product_Price, setProduct_Price] = useState();
    const [Product_description, setProduct_description] = useState();
    const [Product_Features, setProduct_Features] = useState();
    const [Quntity, setQuntity] = useState();
    const handleSubmit = async (e) => {
        const token = localStorage.getItem('UserToken');
        const UserID = localStorage.getItem('UserID');
        e.preventDefault();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        try {
            const response = await axios.post(
                `https://sri-backend.vercel.app/${UserID}/ProductQuery`,
                {
                    Product_Name: Product_Name,
                    Product_Category: Product_Category,
                    Product_Price: Product_Price,
                    Product_description: Product_description,
                    Product_Features: Product_Features,
                    Quntity: Quntity
                },
                config
            );
            toast({
                title: 'Success.',
                description: response.data.Message,
                status: 'success',
                duration: 9000,
                isClosable: true,
              })
            // alert(response.data.Message);
            console.log(response.data)
            setProduct_Name('');
            setProduct_Price('');
            setProduct_Category('');
            setProduct_Features('');
            setProduct_description('');
            setQuntity('');
        } catch (error) {
            toast({
                title: error.response.data.message,
                description: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
            // alert(error.response.data.message);
        }
    };

    return (
        <Box
            m={10}
            mt={5}
            mb={20}
            mx={3}
            bg={'#edeff6'}
            py={5}
            px={{ base: 2, sm: 5 }}
            borderRadius={'lg'}
        >
            <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
            >
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/">
                        Home
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="/ProductEnquiry">
                        Product Enquiry
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box m={10} mt={5} mb={40} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Card
                    overflow="hidden"
                    variant="outline"
                    py={{ base: 5, sm: 10 }}
                    pl={5}
                    pr={{ base: 5, sm: 10 }}
                    fontFamily={"'Poppins', sans-serif"}
                    borderRadius={'xl'}
                >
                    <form >
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Product Name</FormLabel>
                                <Input
                                    type="text"
                                    name="Product_Name"
                                    borderWidth={1}
                                    borderColor='#8c8c8b'
                                    value={Product_Name}
                                    onChange={(e) => { setProduct_Name(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Product Category</FormLabel>
                                <Select
                                    name="Product_Category"
                                    borderWidth={1}
                                    borderColor='#8c8c8b'
                                    value={Product_Category}
                                    onChange={(e) => { setProduct_Category(e.target.value) }}
                                    required
                                >
                                    <option value="">Please Select</option>
                                    {options.map((option, index) => (
                                        <option key={index} value={option._id}>
                                            {option.Category_Name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Product Price</FormLabel>
                                <Input
                                    type="number"
                                    name="Product_Price"
                                    borderWidth={1}
                                    borderColor='#8c8c8b'
                                    value={Product_Price}
                                    onChange={(e) => { setProduct_Price(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Product Description</FormLabel>
                                <Textarea
                                    name="Product_Description"
                                    borderWidth={1}
                                    borderColor='#8c8c8b'
                                    value={Product_description}
                                    onChange={(e) => { setProduct_description(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Product Features</FormLabel>
                                <Textarea
                                    name="Product_Features"
                                    borderWidth={1}
                                    borderColor='#8c8c8b'
                                    value={Product_Features}
                                    onChange={(e) => { setProduct_Features(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Quantity</FormLabel>
                                <Input
                                    type="number"
                                    name="Quantity"
                                    borderWidth={1}
                                    borderColor='#8c8c8b'
                                    value={Quntity}
                                    onChange={(e) => { setQuntity(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <Button
                                type="submit"
                                color={'white'}
                                bg={'#3a0ca3'}
                                size={'lg'}
                                letterSpacing={'1px'}
                                _hover={{ bg: '#3a0ca3e1' }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </VStack>
                    </form>
                </Card>
            </Box>
        </Box>

    )
}

export default ProductEnquiry