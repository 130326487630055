import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Spinner
} from '@chakra-ui/react';
import React,{useEffect,useState} from 'react';
import styles from './Products.module.css';
import construction from '../../assets/construction.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import flyash from '../../assets/flyash.png';
import axios from 'axios';
import { useNavigate } from "react-router";

export default function Products() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isUploading, setIsUploading] = useState(false);


  const Get_All_Category = async () => {
    try {
      setIsUploading(true)
      const response = await axios.get('https://sri-backend.vercel.app/Get_All_Category', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      setCategories(response.data.data);
      setIsUploading(false)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    Get_All_Category();
  }, []);

  const CategoryClick = (item) => {
    navigate('/category', {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  }

  return (
    <Box mt={10} textAlign={'center'}>
      <Text color={'#3a0ca3'} fontSize={'1.2rem'} fontWeight={600}>
        {/* Products */}
      </Text>
      <Text
        fontWeight={600}
        fontSize={'1.5rem'}
        fontFamily={'"Quicksand", sans-serif'}
        letterSpacing={'1.5px'}
        borderBottom={'3px solid #3A0CA3'}
        display={'inline'}
        lineHeight={'3rem'}
        pb={2}
      >
        {/* Building Construction Material & Equipment */}
      </Text>
      {/* <Box display={'flex'} justifyContent={'center'} my={5} pos={'relative'}>
        <Image
          src={construction}
          alt="xxx"
          className="img-fluid"
          style={{ filter: 'brightness(75%)' }}
        />
        <Stack
          pos={'absolute'}
          fontSize={'1.2rem'}
          align={'center'}
          className={styles.stack}
          minW={'100vw'}
        >
          <Flex
            color={'white'}
            fontFamily={"'Quicksand', sans-serif"}
            textShadow={'0 0 2px black'}
            minW={'40vw'}
            justifyContent={'space-between'}
          >
            <Text>Prefabricated Houses</Text>
            <Text>Scaffolding Planks & Plates</Text>
          </Flex>
          <Flex
            color={'white'}
            fontFamily={"'Quicksand', sans-serif"}
            textShadow={'0 0 2px black'}
            minW={'40vw'}
            justifyContent={'space-between'}
          >
            <Text>Construction Machines</Text>
            <Text>Crushing Machines & Plants</Text>
          </Flex>
        </Stack>
      </Box> */}
      <Box px={10}>
        <Swiper
          style={{
            backgroundColor: '#ebedf4',
            padding: '1rem 1.5rem',
            borderRadius: '10px',
            margin: '1rem 0',
          }}
          modules={[Navigation]}
          loop={true}
          spaceBetween={0}
          navigation={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
        >
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              Brick Making Machines
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              Plywoods
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              Wooden Door
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              Passenger Lifts
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              Excavator
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              PVC Pipes
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <Text
              mb={0}
              color={'black'}
              fontWeight={600}
              fontFamily={"'Poppins', sans-serif"}
              letterSpacing={'1px'}
              cursor={'pointer'}
              fontSize={'1rem'}
            >
              Commercials
            </Text>
          </SwiperSlide>
        </Swiper>
        <Swiper
          style={{
            padding: '1rem 1.5rem',
            borderRadius: '10px',
            margin: '1rem 0',
          }}
          modules={[Navigation]}
          loop={true}
          spaceBetween={30}
          navigation={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {
          isUploading ? (
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            ) : (
          categories.map((category, index) => (
            <SwiperSlide key={index}>
              <Stack gap={10}>
                <Card
                  direction={{ base: 'column', sm: 'row' }}
                  overflow="hidden"
                  variant="elevated"
                  px={5}
                  style={{height:'150px'}}
                >
                  <Image
                    objectFit="cover"
                    maxW={{ base: '100%', sm: '200px' }}
                    src={category.Category_Image}
                    alt={category.altText}
                  />
                  <CardBody  onClick={() => { CategoryClick(category) }} textAlign={{ base: 'center', sm: 'left' }} my={'auto'}>
                    <Heading size="sm" lineHeight={'1.5rem'}>
                    {category.Category_Name}
                    </Heading>
                  </CardBody>
                </Card>
              </Stack>
            </SwiperSlide>
          )))}
        </Swiper>
      </Box>
    </Box>
  );
}
