import {
    Box,
    Button,
    Card,
    Flex,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    StackDivider,
    Text,
  } from '@chakra-ui/react';
  import React, { useState } from 'react';
  
  function SellerProductDetailCard({ productdata }) {
    const product = productdata
    const [isProduct, setIsProduct] = useState('product');
    const productFeatures = product.Product_Features.split('\n');
    return (
      <Card
        overflow="hidden"
        variant="outline"
        p={5}
        fontFamily={"'Poppins', sans-serif"}
        my={5}
      >
        <Box bg={'#ebedf4'} px={5} pt={2} borderRadius={'lg'}>
          <Flex gap={10}>
            <Button
              variant={'unstyled'}
              borderBottom={isProduct === 'product' ? '4px solid #3a0ca3' : ''}
              borderRadius={'none'}
              onClick={() => setIsProduct('product')}
              fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
            >
              Products Details
            </Button>
            <Button
              variant={'unstyled'}
              borderRadius={'none'}
              borderBottom={isProduct === 'company' ? '4px solid #3a0ca3' : ''}
              onClick={() => setIsProduct('company')}
              fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
            >
              Company Details
            </Button>
          </Flex>
        </Box>
        <Stack spacing="4" my={5}>
          <Box fontFamily={"'Poppins', sans-serif"}>
            <Text
              fontWeight={600}
              letterSpacing={'1px'}
              pt="2"
              fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
              color={'#3a0ca3'}
            >
              Product Specification
            </Text>
          </Box>
          <Stack
            divider={<StackDivider />}
            spacing="2"
            fontFamily={"'Poppins', sans-serif"}
            letterSpacing={'1px'}
          >
            <Flex gap={30} justifyContent={'space-between'}>
              <Text fontSize="sm" color={'#767676'} mb={0}>
                Power
              </Text>
              <Text fontSize="sm" fontWeight={600} mb={0}>
                {product.Power} W
              </Text>
            </Flex>
            <Flex gap={30} justifyContent={'space-between'}>
              <Text fontSize="sm" color={'#767676'} mb={0}>
                Color
              </Text>
              <Text fontSize="sm" fontWeight={600} mb={0}>
                {product.Color}
              </Text>
            </Flex>
            <Flex gap={30} justifyContent={'space-between'}>
              <Text fontSize="sm" color={'#767676'} mb={0}>
                Warranty
              </Text>
              <Text fontSize="sm" fontWeight={600} mb={0}>
                {product.Warrenty} Year
              </Text>
            </Flex>
            <Flex gap={30} justifyContent={'space-between'}>
              <Text fontSize="sm" color={'#767676'} mb={0}>
                Brand
              </Text>
              <Text fontSize="sm" fontWeight={600} mb={0}>
                {product.Brand}
              </Text>
            </Flex>
            <Flex gap={30} justifyContent={'space-between'}>
              <Text fontSize="sm" color={'#767676'} mb={0}>
                Model Name/Number
              </Text>
              <Text fontSize="sm" fontWeight={600} mb={0}>
                {product.Model_name}
              </Text>
            </Flex>
            <Flex gap={30} justifyContent={'space-between'}>
              <Text fontSize="sm" color={'#767676'} mb={0}>
                Country Of Origin
              </Text>
              <Text fontSize="sm" fontWeight={600} mb={0}>
                {product.Country_of_origin}
              </Text>
            </Flex>
          </Stack>
        </Stack>
        <Text
          fontWeight={600}
          letterSpacing={'1px'}
          pt="2"
          fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
          color={'#3a0ca3'}
        >
          Product Description
        </Text>
        <Text pt="2" fontSize="sm" color={'#767676'} lineHeight={'2rem'}>
          {product.Product_description}
        </Text>
        <Text
          fontWeight={600}
          letterSpacing={'1px'}
          pt="2"
          fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
          color={'#3a0ca3'}
        >
          Features
        </Text>
        {productFeatures.map((feature, index) => (
          <Text pt="2" fontSize="sm" fontWeight={600} letterSpacing={'1px'} key={index}>
            <li >{feature}</li>
          </Text>
  
        ))}
        <Text
          fontWeight={600}
          letterSpacing={'1px'}
          pt="2"
          fontSize={{ base: 'lg', md: 'md', lg: '3xl' }}
          color={'#3a0ca3'}
        >
          Available Color:
        </Text>
        <Text pt="2" fontSize="sm" fontWeight={600} letterSpacing={'1px'}>
          {product?.Available_color?.join(', ')}
        </Text>
      </Card>
    );
  }
  
  
export default SellerProductDetailCard