import { Box, Card, CardFooter, Image, Text, Spinner } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import { useNavigate } from "react-router";
export default function Featured() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isUploading, setIsUploading] = useState(false);


  const Get_All_Category = async () => {
    try {
      setIsUploading(true)
      const response = await axios.get('https://sri-backend.vercel.app/Get_All_Category', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      setCategories(response.data.data);
      setIsUploading(false)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    Get_All_Category();
  }, []);

  const CategoryClick = (item) => {
    navigate('/category', {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  }


  return (
    <div>
      <div>
        <Box mt={20} mb={10} textAlign={'center'}>
          <Text
            fontWeight={600}
            fontSize={'2rem'}
            fontFamily={'"Quicksand", sans-serif'}
            letterSpacing={'1.5px'}
            borderBottom={'3px solid #3A0CA3'}
            display={'inline'}
            pb={2}
          >
            Featured categories
          </Text>
          <Box px={10} mt={10}>
            <Swiper
              modules={[Navigation]}
              loop={true}
              spaceBetween={50}
              slidesPerView={3}
              navigation={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 50,
                },
              }}
            >
              {isUploading ? (
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              ) : (
                categories.map((category) => (
                  <SwiperSlide key={category.id}>
                    <Card maxW="md">
                      <Image
                        objectFit="cover"
                        src={category.Category_Image}
                        alt={category.Category_Name}
                      />

                      <CardFooter
                        onClick={() => { CategoryClick(category) }}
                        alignItems={'center'}
                        justify="center"
                        flexWrap="wrap"
                        sx={{
                          '& > button': {
                            minW: '136px',
                          },
                        }}
                        fontFamily={"'Poppins', sans-serif"}
                        bg={'#3A0CA3'}
                        color={'white'}
                      >
                        <Text mb={0}>{category.Category_Name}</Text>
                      </CardFooter>
                    </Card>
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </Box>
          <Text color={'#3a0ca3'} my={5} fontSize={'1.5rem'} fontWeight={600} cursor={'pointer'}>
            {/* See All */}
          </Text>
        </Box>
      </div>
    </div>
  );
}
