import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Spinner,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import profileImg from '../../../assets/profileImg.png';
import { TiLocation } from 'react-icons/ti';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'

const cloudName = 'dwewlzhdz';
const cloudinaryURL = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;



export default function ProfileCard({ userdata }) {
  const toast = useToast()

  const [isloading, setisloading] = useState(false)

  useEffect(() => {
    setisloading(true);
    const timer = setTimeout(() => {
      setisloading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleImageUpload = async (e) => {
    try {
      setIsUploading(true);
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'New_upload_present');

        const response = await fetch(cloudinaryURL, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.secure_url;
          setIsUploading(false);
          setProfile_Image(imageUrl);
        } else {
          console.error('Error uploading image to Cloudinary:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };


  function formatDate(inputDate) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  }


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [Name, setName] = useState("");
  const [Profile_Image, setProfile_Image] = useState("");

  const openEditModal = () => {
    setIsEditModalOpen(true);
    setName(userdata.Name)
    setProfile_Image(userdata.Profile_Image)
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };


  const Sellertoken = localStorage.getItem('SellerToken');
  const UserToken = localStorage.getItem('UserToken');

  const UpdateUserProfile = async () => {
    try {
      const token = localStorage.getItem('UserToken');
      const UserID = localStorage.getItem('UserID');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `https://sri-backend.vercel.app/${UserID}/UpdateUser`,
        {
          Name: Name,
          Profile_Image: Profile_Image,
        },
        config
      );

      // alert(response.data.message);
      window.location.reload();
    } catch (error) {
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // alert(error.response.data.message);
    }

    closeEditModal();
  };

  const UpdateSellerProfile = async () => {
    try {
      const token = localStorage.getItem('SellerToken');
      const UserID = localStorage.getItem('SellerID');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `https://sri-backend.vercel.app/${UserID}/UpdateSellerprofile`,
        {
          Name: Name,
          Profile_Image: Profile_Image,
        },
        config
      );

      window.location.reload();
    } catch (error) {
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // alert(error.response.data.message);
    }

    closeEditModal();
  };


  return (
    <Card
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      variant="outline"
      py={{ base: 5, sm: 10 }}
      pl={5}
      pr={{ base: 5, sm: 10 }}
      fontFamily={"'Poppins', sans-serif"}
      borderRadius={'xl'}
    >
      {isloading
        ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '200px' }}>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </div>
        ) : (
          <>
            <Image
              objectFit="cover"
              maxW={{ base: '100%', sm: '200px' }}
              src={userdata.Profile_Image || profileImg}
              alt="profile"
              minW={'20%'}
              style={{ borderRadius: '20%' }}
            />

            <Stack textAlign={{ base: 'center', sm: 'left' }}>
              <CardBody display={'flex'} flexDir={'column'} justifyContent={'center'}>
                <Heading
                  size="lg"
                  fontFamily={"'Poppins', sans-serif"}
                  fontWeight={600}
                >
                  {userdata.Name}{' '}
                </Heading>

                <Text py="2" color={'#353e5c'}>
                  Member since {formatDate(userdata.date)}
                </Text>
              </CardBody>

              <CardFooter mx={{ base: 'auto', sm: '0' }}>
                <Flex align={'center'} gap={2}>
                  <TiLocation size={25} color="#3a0ca3" />
                  <Text py="2" mb={0}>
                    {userdata.Address ? userdata.Address : 'Default Address'}
                  </Text>
                </Flex>
              </CardFooter>
            </Stack>
            <Button
              color={'white'}
              bg={'#3a0ca3'}
              size={'lg'}
              ml={{ base: '0', sm: 'auto' }}
              my={'auto'}
              letterSpacing={'1px'}
              _hover={{ bg: '#3a0ca3e1' }}
              onClick={openEditModal}
            >
              Edit
            </Button>


            <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <label htmlFor="editName">Name</label>
                  <Input
                    type="text"
                    id="editName"
                    placeholder="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </ModalBody>
                <ModalBody>
                  <label htmlFor="editProfileImage">Profile Image</label>
                  <input
                    type="file"
                    id="editProfileImage"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <Flex alignItems="center" justifyContent="center">
                    {isUploading ? (
                      <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                      />
                    ) : (
                      <Image src={Profile_Image} maxW="100%" alt="Profile" />
                    )}
                  </Flex>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color={'white'}
                    bg={'#3a0ca3'}
                    size={'md'}
                    ml={{ base: '0', sm: 'auto' }}
                    my={'auto'}
                    letterSpacing={'1px'}
                    _hover={{ bg: '#3a0ca3e1' }}
                    onClick={() => {
                      if (Sellertoken) {
                        UpdateSellerProfile();
                      } else if (UserToken) {
                        UpdateUserProfile();
                      }
                    }}
                  >
                    Save
                  </Button>
                  <Button onClick={closeEditModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
    </Card>
  );
}
