import React from 'react';
import {
    Box,
    Text,
    Heading,
    Center,
    Card,
    Divider,
} from '@chakra-ui/react';
import Footer from '../components/Footer/Footer';

const TermsAndConditions = () => {
    return (
        <>
            <Box m={10} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Center>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '1.5rem', md: '2rem' }}
                        fontFamily={'"Quicksand", sans-serif'}
                        letterSpacing={'1.5px'}
                        borderBottom={'3px solid #3A0CA3'}
                        display={'inline'}
                        pb={2}
                    >
                        Terms and Conditions
                    </Heading>
                </Center>
                <Card
                    overflow="hidden"
                    variant="outline"
                    py={{ base: 5, md: 10 }}
                    px={{ base: 5, sm: 10 }}
                    fontFamily={"'Poppins', sans-serif"}
                    borderRadius={'xl'}
                    my={5}
                >
                    <Text fontSize={{ base: 'sm', md: 'md' }} color="gray.600">
                        <strong>1. Introduction</strong><br />
                        Welcome to our Terms and Conditions. These are important and affect your legal rights, so please read them carefully.
                        <Divider my={4} />
                        <strong>2. Accepting the Terms</strong><br />
                        By using our services, you agree to be bound by these Terms and our Privacy Policy.
                        <Divider my={4} />
                        <strong>3. Changes to the Terms</strong><br />
                        We may update our Terms from time to time. You are responsible for checking our Terms regularly.
                        <Divider my={4} />
                        <strong>4. User Conduct</strong><br />
                        You agree to abide by all applicable laws and regulations in your use of our services.
                        <Divider my={4} />
                        {/* Add more sections based on your needs */}
                    </Text>
                </Card>
            </Box>
            <div >
                <Footer />
            </div>

        </>
    );
};

export default TermsAndConditions;
