import { Menu, MenuButton, MenuList, MenuItem, Image, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css';
import { IconHomeCheck } from '@tabler/icons-react';
import { Box } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import LoginModal from '../LoginModal/LoginModal';
import { Link, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react'
import Logoimage from '../../assets/Kovai_Page_Logo.png'

export default function Navbar() {
  const toast = useToast()
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isSellerLogin, setIsSellerLogin] = useState(false);
  const [Username, setUsername] = useState('');

  const Logout = () => {
    toast({
      title: 'Logout Succesfull.',
      description: "Logout Succesfull",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    localStorage.clear();
    setTimeout(() => {
      navigate('/');
      window.location.reload();
    }, 2000);
  }

  useEffect(() => {
    const dataToken = localStorage.getItem('UserToken');
    const username = localStorage.getItem('UserName');

    const SellerToken = localStorage.getItem('SellerToken');
    const SellerName = localStorage.getItem('SellerName');
    if (dataToken) {
      setUsername(username)
      setIsLogin(true);
    }
    else if (SellerToken) {
      setUsername(SellerName)
      setIsLogin(true);
      setIsSellerLogin(true);
    }
    else {
      setIsLogin(false);
    }
  }, []);
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ zIndex: '2' }}>
      {/* <a
        className="navbar-brand d-flex align-items-center mr-4"
        href="/"
        style={{ fontWeight: '600' }}
      >
        <Box>
          <Image src={Logoimage} width={20} height={20}   />
        </Box>
        Kovai Pages
      </a> */}
      <a
        className="navbar-brand d-flex align-items-center mr-4"
        href="/"
      >
        <Box>
          <Image src={Logoimage} style={{ width: '150px', height: '100px' }} />
        </Box>
        {/* Kovai Pages */}
      </a>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className={`navbar-nav mr-auto ${styles.navItemContainer}`}>
          <NavLink className="nav-link my-2" to="/">
            <li className="nav-item mx-2">
              Home <span className="sr-only">(current)</span>
            </li>
          </NavLink>
          <NavLink className="nav-link my-2" to="/about">
            <li className="nav-item mx-2">About</li>
          </NavLink>
          <NavLink className="nav-link my-2" to="/products">
            <li className="nav-item mx-2">Products</li>
          </NavLink>
          <NavLink className="nav-link my-2" to="/blogs">
            <li className="nav-item mx-2">Blogs</li>
          </NavLink>
          {isLogin && (
            <NavLink className="nav-link my-2" to="/profile">
              <li className="nav-item mx-2">Profile</li>
            </NavLink>
          )}
          {isLogin && !isSellerLogin && (
            <NavLink className="nav-link my-2" to='/ProductEnquiry'>
              <li className="nav-item mx-2">Product enquiry</li>
            </NavLink>
          )}
          {isSellerLogin && (
            <NavLink className="nav-link my-2" to="/ProductUpload">
              <li className="nav-item mx-2">Product Upload</li>
            </NavLink>
          )}
          {isSellerLogin && (
            <NavLink className="nav-link my-2" to="/SellerEnquire">
              <li className="nav-item mx-2">Enquiries</li>
            </NavLink>
          )}
        </ul>
        {isLogin ? (
          <div
            className={`d-flex align-items-center justify-content-center mr-4 my-3`}
            style={{ textDecoration: 'none', color: 'inherit', margin: '0 auto' }}
            data-target="#exampleModalCenter"
          >
            <Box className="d-inline-block align-top mx-2">
              <BiUserCircle size={25} />
            </Box>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {Username}
              </MenuButton>
              <MenuList>
                <MenuItem as={Link} to="/profile">Profile</MenuItem>
                <MenuItem as={Link} to="/products">Product</MenuItem>
                <MenuItem as={Link} to="/blogs">Blogs</MenuItem>
                <MenuItem as={Link} onClick={Logout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </div>)
          : (<a
            className={`d-flex align-items-center justify-content-center mr-4 my-3 ${styles.navItemContainer}`}
            href="/"
            style={{ textDecoration: 'none', color: 'inherit', margin: '0 auto' }}
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            <Box className="d-inline-block align-top mx-2">
              <BiUserCircle size={25} />
            </Box>
            Login/Register
          </a>)}
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <LoginModal />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
