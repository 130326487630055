import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Button,
    Card,
    CardBody,
    AspectRatio,
    Image,
    Stack,
    Heading,
    Flex,
    Spinner,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import axios from 'axios';
import { useNavigate,useLocation } from "react-router";


function Categorywisedata() {
    const navigate = useNavigate();
    let location = useLocation();
    const category = location.state ? location.state.item : null;
    const category_id = category ? category._id : null;
    const Category_Name = category ? category.Category_Name : null;

    const [Products, setProducts] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const Get_All_products = async () => {
        try {
            setIsUploading(true)
            const response = await axios.get(`https://sri-backend.vercel.app/Get_Approved_Product_for_all_user_by_category/${category_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            setProducts(response.data.data);
            setIsUploading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Get_All_products();
    }, [location]);

    const ProductClick = (item) => {
        navigate('/details', {
            state: {
                item: item,
            },
        });
        window.scrollTo(0, 0);
    }

    return (
        <>
            <Box  m={10} mt={5} mb={40} mx={3} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/">
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/profile">
                            Categorywise Product page
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <div>
                        <Box mt={10} mb={10} textAlign={'center'}>
                            <Text
                                fontWeight={600}
                                fontSize={'2rem'}
                                fontFamily={'"Quicksand", sans-serif'}
                                letterSpacing={'1.5px'}
                                borderBottom={'3px solid #3A0CA3'}
                                display={'inline'}
                                pb={2}
                            >
                                All Categorywise Products
                            </Text>
                            <Box px={{ base: 2, sm: 5, md: 10 }}>
                                {isUploading ? (
                                    <Spinner mt={10} thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                                ) : (
                                    Products.length === 0 ? (
                                        <text>No Products is Uploaded Yet in {Category_Name} category</text>
                                    ) : (
                                        <Flex flexWrap="wrap" justifyContent="space-between">
                                            {Products.map((product, index) => (
                                                <Box key={index} width={{ base: '100%', sm: '48%', md: '30%' }} mb={{ base: 4, md: 8 }} mt={10}>
                                                    <Card maxW="sm">
                                                        <CardBody>
                                                            <AspectRatio ratio={1.3}>
                                                                <Image src={product?.product.Product_Image} borderRadius="lg" alt="" className="img-fluid" />
                                                            </AspectRatio>
                                                            <Stack mt="6" spacing="3">
                                                                <Heading size="md" textAlign={'left'}>
                                                                    {product.product.Product_Name.slice(0, 50)}...
                                                                </Heading>
                                                                <Text color="#3a0ca3" fontSize="xl" textAlign={'left'} fontWeight={600}>
                                                                    Rs {product.product.Product_Price}/ piece
                                                                </Text>
                                                                <Flex align={'center'} justifyContent={'space-between'} color={'#889099'}>
                                                                    <Text>{product.product.Brand}</Text>
                                                                    <Text>{product.user.Address}</Text>
                                                                </Flex>
                                                                <Button
                                                                    size={'md'}
                                                                    bg={'#3A0CA3'}
                                                                    color={'white'}
                                                                    fontFamily={'"Inter", sans-serif'}
                                                                    _hover={{ bg: '#3A0CA3e1' }}
                                                                    letterSpacing={'1.5px'}
                                                                    onClick={() => { ProductClick(product) }}
                                                                >
                                                                    Show More
                                                                </Button>
                                                            </Stack>
                                                        </CardBody>
                                                    </Card>
                                                </Box>
                                            ))}
                                        </Flex>
                                    ))}
                            </Box>
                            <Text color={'#3a0ca3'} my={5} fontSize={'1.5rem'} fontWeight={600} cursor={'pointer'}>
                                {/* See All */}
                            </Text>
                        </Box>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    );
}

export default Categorywisedata