import React from 'react';
import {
    Box,
    Text,
    Heading,
    Center,
    Card,
    Divider,
} from '@chakra-ui/react';
import Footer from '../components/Footer/Footer';

const Disclaimer = () => {
    return (
        <>
            <Box m={10} mx={3} mb={8} bg={'#edeff6'} py={5} px={{ base: 2, sm: 5 }} borderRadius={'lg'}>
                <Center>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '1.5rem', md: '2rem' }}
                        fontFamily={'"Quicksand", sans-serif'}
                        letterSpacing={'1.5px'}
                        borderBottom={'3px solid #3A0CA3'}
                        display={'inline'}
                        pb={2}
                    >
                        Disclaimer
                    </Heading>
                </Center>
                <Card
                    overflow="hidden"
                    variant="outline"
                    py={{ base: 5, md: 10 }}
                    px={{ base: 5, sm: 10 }}
                    fontFamily={"'Poppins', sans-serif"}
                    borderRadius={'xl'}
                    my={5}
                >
                    <Text fontSize={{ base: 'sm', md: 'md' }} color="gray.600">
                        <strong>1. Disclaimer Content</strong><br />
                        This is a sample disclaimer page. Customize this content based on your needs and legal requirements.
                        <Divider my={4} />
                        <strong>2. Limitation of Liability</strong><br />
                        The information provided on this website is for general informational purposes only.
                        <Divider my={4} />
                        <strong>3. Accuracy of Information</strong><br />
                        We do not guarantee the accuracy or completeness of the information on this website.
                        <Divider my={4} />
                        <strong>4. External Links</strong><br />
                        This website may contain links to external websites. We are not responsible for the content of these external sites.
                        <Divider my={4} />
                    </Text>
                </Card>
            </Box>
            <Footer mb={5} />
        </>
    );
};

export default Disclaimer;
